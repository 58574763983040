import { Injectable } from '@angular/core';

import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  private _process: boolean;

  get process(): boolean {
    return this._process;
  }

  setProcess(value: boolean, form?: FormGroup): void {
    if (form) {
      value ? form.disable() : form.enable();
    }
    this._process = value;
  }
}
