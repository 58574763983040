import { Injectable } from '@angular/core';

import { MatDialogRef, MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { SignInComponent } from '../components/system/authentication/sign-in/sign-in.component';
import { ForgotPasswordComponent } from '../components/system/authentication/forgot-password/forgot-password.component';
import { ConfirmDialogComponent } from '../components/system/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  openSignInDialog(): void {
    this.dialog.open(SignInComponent, { disableClose: true });
  }

  openForgotPasswordDialog(): void {
    this.dialog.open(ForgotPasswordComponent, { disableClose: true });
  }

  openConfirmDialog(title: string, message: string): Observable<any> {

    let ref: MatDialogRef<ConfirmDialogComponent>;

    ref = this.dialog.open(ConfirmDialogComponent);

    ref.componentInstance.title = title;
    ref.componentInstance.message = message;

    return ref.afterClosed();
  }
}
