import { Routes } from '@angular/router';

import { NoContentComponent } from './components/no-content/no-content.component';
import { AuthenticationComponent } from './components/system/authentication/authentication.component';
import { DashboardComponent } from './components/system/dashboard/dashboard.component';
import { ArticlesComponent } from './components/system/articles/articles.component';
import { ArticleComponent } from './components/system/articles/article/article.component';
import { ServicesComponent } from './components/system/services/services.component';
import { ServiceComponent } from './components/system/services/service/service.component';

import { NoContentImageResolver } from './resolvers/no-content-image-resolver';

import { AuthenticationGuard } from './guards/authentication.guard';

export const ROUTES: Routes = [
  { path: 'system/articles/:id', component: ArticleComponent, canActivate: [ AuthenticationGuard ] },
  { path: 'system/articles', component: ArticlesComponent, canActivate: [ AuthenticationGuard ] },
  { path: 'system/services/:id', component: ServiceComponent, canActivate: [ AuthenticationGuard ] },
  { path: 'system/services', component: ServicesComponent, canActivate: [ AuthenticationGuard ] },
  { path: 'system/dashboard', component: DashboardComponent, canActivate: [ AuthenticationGuard ] },
  { path: '',      component: AuthenticationComponent, canActivate: [ AuthenticationGuard ] },
  { path: '**',    component: NoContentComponent, resolve: { background: NoContentImageResolver } }
];
