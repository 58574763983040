import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ckeditor4-angular';
import { MaterialModule } from '../material/material.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { NavbarModule } from '../navbar/navbar.module';

const SHARED_MODULES: any[] = [
  CommonModule,
  ReactiveFormsModule,
  CKEditorModule,
  MaterialModule,
  ToolbarModule,
  NavbarModule
];

@NgModule({
  imports: SHARED_MODULES,
  exports: SHARED_MODULES
})
export class SharedModule { }
