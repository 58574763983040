const devFirebaseConfig = {
  apiKey: 'AIzaSyAN1K48fuuw4LjU1Sn8SmB1_IrroSIazbo',
  authDomain: 'testust-com-ua.firebaseapp.com',
  databaseURL: 'https://testust-com-ua.firebaseio.com',
  projectId: 'testust-com-ua',
  storageBucket: 'testust-com-ua.appspot.com',
  messagingSenderId: '727780669043',
  appId: '1:727780669043:web:91ff99526f261dce'
};

const prodFirebaseConfig: any = {
  apiKey: 'AIzaSyDMjiBAbMNZubVfOx2Ef1gdo6MmZ_QPynU',
  authDomain: 'alterust-com-ua.firebaseapp.com',
  databaseURL: 'https://alterust-com-ua.firebaseio.com',
  projectId: 'alterust-com-ua',
  storageBucket: 'alterust-com-ua.appspot.com',
  messagingSenderId: '898349263595',
  appId: '1:898349263595:web:7e9b58f6336b6ffb'
};

export const ENV_FIREBASE_CONFIG: any = process.env.NODE_ENV !== 'production' ?
  devFirebaseConfig :
  prodFirebaseConfig;
