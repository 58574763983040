import { Injectable, NgZone } from '@angular/core';

import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { MatSnackBar } from '@angular/material/snack-bar';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    public afa: AngularFireAuth,    // Inject Firebase auth service
    public afs: AngularFirestore,   // Inject Firestore service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private snackBar: MatSnackBar
  ) {
  }

  // Sign in with email/password
  async signIn(email, password, dialog) {
    try {
      await this.afa.auth.signInWithEmailAndPassword(email, password)
        .then((value) => {
          this.setUserData(value.user);
          this.ngZone.run(() => {
            this.router.navigate(['./system/dashboard'])
              .then(() => {
                dialog.close();
                this.snackBar.open('Успешный вход в систему.', 'Успешно');
              })
              .catch((reason) => console.log(reason));
          });
        })
        .catch((reason) => this.snackBar.open(reason.message, 'Ошибка'));
    } catch (error) {
      this.snackBar.open(error.message, 'Ошибка');
    }
  }

  // Forgot password
  async forgotPassword(passwordResetEmail, dialog) {
    try {
      await this.afa.auth.sendPasswordResetEmail(passwordResetEmail)
        .then(() => {
          this.ngZone.run(() => {
            this.router.navigate(['./'], {queryParams: {action: 'sign-in'}})
              .then(() => {
                dialog.close();
                this.snackBar.open('Сообщение с инструкцией отправлено на эл. почту, пожалуйста, подтвердите ваш адрес эл. почты.', 'Успешно');
              })
              .catch((reason) => console.log(reason));
          });
        })
        .catch((reason) => this.snackBar.open(reason.message, 'Ошибка'));
    } catch (error) {
      this.snackBar.open(error.message, 'Ошибка');
    }
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = this.afa.auth.currentUser;
    return user !== null && user.emailVerified !== false;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  private setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL: user.photoURL,
      providerId: user.providerId,
      uid: user.uid,
    };
    return userRef.set(userData, {
      merge: true
    });
  }

  // Sign out
  async signOut() {
    try {
      await this.afa.auth.signOut()
        .then(() => {
          this.router.navigate(['./'])
            .then(() => {
              this.snackBar.open('Успешный выход из системы.', 'Успешно');
            })
            .catch((reason) => console.log(reason));
        })
        .catch((reason) => this.snackBar.open(reason.message, 'Ошибка'));
    } catch (error) {
      this.snackBar.open(error.message, 'Ошибка');
    }
  }
}
