import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Service } from '../../../interfaces/service';

import { DialogService} from '../../../services/dialog.service';
import { ServiceService } from '../../../services/service.service';
import { ProcessService } from '../../../services/process.service';

import { APP_URL } from '../../../app.config';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  url = APP_URL;

  services: Service[];

  constructor(private dialogService: DialogService,
              private snackBar: MatSnackBar,
              private serviceService: ServiceService,
              private processService: ProcessService) { }

  ngOnInit() {
    this.processService.setProcess(true);
    this.serviceService.getServices()
      .pipe(untilDestroyed(this))
      .subscribe(
        (data) => {
          this.services = data;
          this.processService.setProcess(false);
        }, () => {
          this.processService.setProcess(false);
          this.snackBar.open('Сервис недоступен', 'Ошибка');
        }, () => {
          this.processService.setProcess(false);
        });
  }

  delete(service: Service) {
    this.dialogService.openConfirmDialog('Удаление услуги', 'Вы уверены, что хотите полностью удалить эту услугу?')
      .pipe(untilDestroyed(this))
      .subscribe((ok) => {
        if (ok) {
          this.serviceService.deleteService(service)
            .catch((reason) => console.log(reason));
        }
      });
  }

}
