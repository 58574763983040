import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Service } from '../../../../interfaces/service';

import { ServiceService } from '../../../../services/service.service';
import { ProcessService } from '../../../../services/process.service';

import { editorConfig } from '../../../../editor.config';

import { widgetConfig } from '../../../../widget.config';

declare var cloudinary: any;

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  @ViewChild('thumbnails', { static: false, read: ElementRef }) thumbnails: ElementRef;

  service: Service = {} as Service;

  serviceForm: FormGroup;

  config: any;

  hidden = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private serviceService: ServiceService,
    private processService: ProcessService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {

    this.config = editorConfig;

    this.serviceForm = this.formBuilder.group({
      title: [null, Validators.required],
      content: [null, Validators.required],
      visible: null
    });
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.service.id = params['id'];

        if (this.service.id !== 'new') {
          this.processService.setProcess(true);
          this.serviceService.showService(this.service)
            .pipe(untilDestroyed(this))
            .subscribe(
              (data) => {
                if (data.exists) {
                  const service = data.data() as Service;
                  this.serviceForm.patchValue({
                    title: service.title,
                    content: service.content,
                    visible: service.visible
                  });
                  this.service.publicId = service.publicId;
                } else {
                  this.snackBar.open('Услуга не найдена', 'Ошибка');
                }
              }, () => {
                this.snackBar.open('Сервис недоступен', 'Ошибка');
              }, () => {
                this.processService.setProcess(false);
              });
        } else {
          return;
        }
      });
  }

  saveService() {
    this.processService.setProcess(true, this.serviceForm);
    const service = {...this.service, ...this.serviceForm.value} as Service;
    if (service.id === 'new') {
      const omit = (object, property) => {
        const {[property]: _, ...result} = object;
        return result;
      };
      this.serviceService.createService(omit(service, 'id')).then(
        () => {
          this.router.navigate(['./system/services'])
            .then(() => this.snackBar.open('Услуга успешно создана.', 'Успешно'))
            .catch((reason) => console.log(reason));
        },
        (error) => {
          this.snackBar.open(error.message, 'Ошибка');
        })
        .finally(() => this.processService.setProcess(false, this.serviceForm));
    } else {
      this.serviceService.updateService(service).then(
        () => {
          this.router.navigate(['./system/services'])
            .then(() => this.snackBar.open('Услуга успешно обновлена.', 'Успешно'))
            .catch((reason) => console.log(reason));
        },
        (error) => {
          this.snackBar.open(error.message, 'Ошибка');
        })
        .finally(() => this.processService.setProcess(false, this.serviceForm));
    }
  }

  onUpload() {
    this.processService.setProcess(true, this.serviceForm);
    cloudinary.openUploadWidget(widgetConfig,
      (error, result) => {
        if (result && result.event === 'success') {
          this.hidden = true;
          if (this.thumbnails.nativeElement.firstElementChild.children.length > 1) {
            this.thumbnails.nativeElement.firstElementChild.children.item(0).remove();
          }
          this.service.publicId = result.info.public_id;
        }
        if (error) {
          this.snackBar.open(error, 'Ошибка');
        }
      });
    this.processService.setProcess(false, this.serviceForm);
  }

  onCancel() {
    this.router.navigate(['./system/services'])
      .catch((reason) => console.log(reason));
  }
}
