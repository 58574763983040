export const editorConfig = {
  language: 'ru',
  toolbarGroups: [
    { name: 'document', groups: [ 'mode' ] },
    { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
    { name: 'styles', groups: [ 'styles' ] },
    { name: 'links', groups: [ 'links' ] },
    { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
    { name: 'paragraph', groups: [ 'list', 'indent', 'blocks' ] },
    { name: 'tools', groups: [ 'tools' ] }
  ],
  removeButtons: 'Subscript,Superscript,PasteText,PasteFromWord,Styles,About',
  format_tags: 'p;h1;h2;h3',
  forcePasteAsPlainText: true
};
