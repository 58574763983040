import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DialogService } from '../services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private afa: AngularFireAuth,
    private dialogService: DialogService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.afa.authState
      .pipe(
        map((user) => {
          if (user) {
            return true;
          } else {
            this.dialogService.openSignInDialog();
            return false;
          }
        })
      );
  }
}
