import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { Article } from '../interfaces/article';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  private collection: AngularFirestoreCollection<Article>;

  constructor(private afs: AngularFirestore) { }

  getArticles() {
    this.collection = this.afs.collection<Article>('articles');
    return this.collection.snapshotChanges().pipe(
      map((actions) => actions.map((a) => {
        return {
          id: a.payload.doc.id,
          ...a.payload.doc.data()
        } as Article;
      })));
  }

  showArticle(article: Article) {
    return this.afs.collection<Article>('articles').doc(`${article.id}`).get();
  }

  createArticle(article: Article) {
    return this.afs.collection<Article>('articles').doc(this.afs.createId()).set(article);
  }

  updateArticle(article: Article) {
    return this.afs.collection<Article>('articles').doc(`${article.id}`).update(article);
  }

  deleteArticle(article: Article) {
    return this.afs.collection<Article>('articles').doc(`${article.id}`).delete();
  }
}
