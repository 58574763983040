import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '../../services/authentication.service';

import { APP_URL } from '../../app.config';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  url = APP_URL;

  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

}
