import { Component, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { DialogService } from '../../../services/dialog.service';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnDestroy, OnInit {

  constructor(private dialogService: DialogService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        switch (params['action']) {
          case 'sign-in':
            this.dialogService.openSignInDialog();
            break;
          case 'forgot-password':
            this.dialogService.openForgotPasswordDialog();
            break;
          default:
            this.router.navigate(['./'], {relativeTo: this.activatedRoute})
              .catch((reason) => console.log(reason));
        }
      });
  }

  ngOnDestroy() {
  }
}
