import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private httpClient: HttpClient) { }

  sendEmail(user: User): Observable<any> {
    return this.httpClient.post('https://mandrillapp.com/api/1.0/messages/send.json',
      {
        key: 'jBwZK_9YRCj6z42JdLHfNA',
        message: {
          html: user.displayName + '<br>' + user.phoneNumber,
          subject: 'Заявка с сайта',
          from_email: 'admin@alterust.com.ua',
          from_name: 'Администратор',
          to: [{
            email: 'office@alterust.com.ua',
            name: 'Юридическая компания «АльтерЮст»'
          }]
        }
      });
  }
}
