import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { map } from 'rxjs/operators';

import { Service } from '../interfaces/service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private collection: AngularFirestoreCollection<Service>;

  constructor(private afs: AngularFirestore) { }

  getServices() {
    this.collection = this.afs.collection<Service>('services');
    return this.collection.snapshotChanges().pipe(
      map((actions) => actions.map((a) => {
        return {
          id: a.payload.doc.id,
          ...a.payload.doc.data()
        } as Service;
      })));
  }

  showService(service: Service) {
    return this.afs.collection<Service>('services').doc(`${service.id}`).get();
  }

  createService(service: Service) {
    return this.afs.collection<Service>('services').doc(this.afs.createId()).set(service);
  }

  updateService(service: Service) {
    return this.afs.collection<Service>('services').doc(`${service.id}`).update(service);
  }

  deleteService(service: Service) {
    return this.afs.collection<Service>('services').doc(`${service.id}`).delete();
  }
}
